import React, { useState } from 'react';
import './FaqPage.css';

const FaqPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      category: 'General Information',
      items: [
        {
          question: 'Who are we?',
          answer:
            'We are a team of IITians who started ETF ki Dukaan as a side project to manage our own funds. As we realized the need for such a product for the broader public, we decided to launch it and make it available to all investors.',
        },
        {
          question: 'What is ETF ki Dukaan?',
          answer:
            'ETF ki Dukaan is a platform offering dynamic, backtested ETF investment strategies designed to provide steady returns with low risk. We provide automated execution of strategies and allow clients to track their holdings, see personalized buy/sell recommendations, and make informed investment decisions.',
        },
        {
          question: 'Who is ETF ki Dukaan for?',
          answer:
            'ETF ki Dukaan is for anyone who wants to see their money grow without the associated stress of deciding what to buy or when to sell. Simply invest in ETFs, and let our backtested strategies do the work while you watch your portfolio grow over time.',
        },
        {
          question: 'How does ETF ki Dukaan work?',
          answer:
            'You simply choose a strategy or a combination of strategies, and we handle the rest. Depending on your plan, trades are automatically executed in your account, or you can log in to receive personalized buy/sell recommendations for your account. You can act on these recommendations with just one click due to broker integration.',
        },
        {
          question: 'What is the process to start investing with etfkidukaan.in?',
          answer:
            "To start, sign up on our website, select your preferred strategy, and connect your Shoonya or AngelOne demat account. If you decide to be a Gold tier member, you can now just sit back and see magic happen in your account as our algos make your money grow. If you're in the Silver tier, log in to see personalized recommendations, which you can follow directly from the website itself.",
        },
      ],
    },
    {
      category: 'Investing in ETFs',
      items: [
        {
          question: 'What are ETFs (Exchange-Traded Funds)?',
          answer:
            'ETFs are investment funds that are traded on stock exchanges, similar to stocks. They typically track indices or sectors and offer diversified exposure at a relatively low cost, making them an efficient and flexible investment option.',
        },
        {
          question: 'How are ETFs better than mutual funds?',
          answer:
            'ETFs generally have lower expense ratios compared to mutual funds, and they offer greater liquidity as they are traded on the stock exchange throughout the day. They also provide better tax efficiency and more control over your investments. ETFs typically carry fewer restrictions than mutual funds.',
        },
        {
          question: 'Why should I invest in ETFs?',
          answer:
            'ETFs offer diversification, low expense ratios, and liquidity. They are perfect for those looking to invest in a wide range of sectors or indices without taking on excessive risk.',
        },
        {
          question: 'Are ETFs risk-free?',
          answer:
            'No investment is risk-free, including ETFs. However, ETFs generally offer lower risk due to their diversified nature. Our strategies focus on minimizing risk and maximizing returns through careful ETF selection and backtesting.',
        },
        {
          question: 'How much capital do I need to start investing in ETFs?',
          answer:
            "The capital required depends on the price of the ETFs you're interested in. We suggest starting with an amount you're comfortable with, ensuring diversification to manage risk. In order to be profitable after tax, we suggest you to start with at least 50,000/- initial for this strategy. We are soon coming up with an SIP strategy in which you can invest any amount as per your choice.",
        },
      ],
    },
    {
        category: "Services Offered",
        items: [
            {
                question: "What exactly do we do for you?",
                answer: "In the most simple words, we provide you a better alternative than Mutual Funds. With same safety, we help you achieve better returns than mutual funds. You just choose a strategy or combination of strategies, and the website handles everything else. Depending on your plan, trades are automatically executed, or we provide personalized buy/sell recommendations for your account. We also guarantee you the best-in-class customer support where we answer each one of your questions till your satisfaction.",
            },
            {
                question: "What are the buy/sell recommendations?",
                answer: "We provide personalized buy/sell recommendations for clients in the Silver tier. Clients can log in to see these recommendations and act upon them with just one click, making the process seamless. For the clients in the Free tier, we provide general buy sell recommendations which they may follow on any demat account.",
            },
            {
                question: "What is the holdings tracking service?",
                answer: "Our website allows you to monitor the performance of your ETF investments and ensure your portfolio aligns with your goals. You can track your holdings, see the profits made over time and even place manual orders, right from the website itself.",
            },
            {
                question: "Can I select a custom strategy?",
                answer: "Currently, we offer one predefined strategy with full automation. We are actively backtesting additional strategies, which will be made available once they're ready and proven to fit your needs. We only launch those strategies which we ourselves use in our accounts.",
            },
            {
                question: "What does backtesting mean?",
                answer: "Backtesting involves testing a trading strategy on historical data to see how it would have performed. It helps us understand the potential risks and rewards before implementing a strategy in real-time.",
            },
            {
                question: "Is automation available for executing ETF strategies?",
                answer: "Yes, we offer automation for the strategy we provide. Trades are executed automatically for clients on the Gold plan, while Silver plan users can login to see their personalised buy/sell recommendations any time and act on them with one click through broker integration.",
            },
            
        ]
    },
    {
        category:"Brokers and Integrations",
        items:[
            {
                question:"Which all brokers do you support?",
                answer:"Currently, we support two brokers: Shoonya (a zero-brokerage broker, though they have their own quirks) and AngelOne (a leading broker in the industry)"

            },
            {
                question:"I have an existing account with Zerodha/Groww. Why can I not use that account?",
                answer:"Zerodha charges a significant fee of ₹2,360 per month just for API access, making it impractical for our use case. Groww does not provide API access yet, which makes integration technically infeasible. We recommend clients have a separate demat account for ETFs to fully experience the benefits of ETF investments, although it is not mandatory."

            },
            {
                question:"Can a client ask for a particular broker's integration?",
                answer:"Yes, you can reach out to our support team via WhatsApp, and we'll try our best to accommodate your request for integrating a specific broker."

            },
            
        ]
    },
    {
        category: "Account and Support",
        items :[ 
            {
                question:"How can I update my contact details?",
                answer:"You can update your contact details by logging into your account settings on our website. This ensures that your account remains up to date. Or else, you can just get in touch with our customer support over Whatsapp chat, and post-verification, we shall update your details."
            },
            {
                question:"How do I contact customer support?",
                answer:"We are available on the most convenient tool, Whatsapp. Just go to Contact Us and you’ll find the link to connect to us."
            },
            {
                question:"What if I forget my account password?",
                answer:"You can easily reset your password by clicking on the `Forgot Password` link on the login page. A password reset link will be sent to your registered email."
            },
            {
                question:"Is my personal information secure?",
                answer:"Information security has been our number one priority since we started. We use industry-standard encryption and security measures to protect your data. Not even our employees can access any of your security credentials."
            },
            {
                question:"Will I be able to withdraw my money anytime?",
                answer: "100%. Your funds and holdings will always remain in the demat account fully in your control. You can revoke access for the website code simply by changing your password. You can sell your holdings and withdraw your money any day you want."
            },
            {
                question:"How much access will you have to my demat account?",
                answer: "Upon integrating the broker credentials, the website will have access to your demat account information such as holdings, positions, funds etc. However, there is no way to move even a single rupee out of your demat account. Even if a withdrawal request was possible, the money is always credited to the connected bank account, which will be yours"
            },


        ]
    },
  ];

  return (
    <div className="faq-container">
      <h1 className="faq-heading">FAQ</h1>
      {faqData.map((category, categoryIndex) => (
        <div key={categoryIndex} className="faq-category">
          <h2 className="faq-category-title">{category.category}</h2>
          {category.items.map((item, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question" onClick={() => handleToggle(`${categoryIndex}-${index}`)}>
                <span>{item.question}</span>
                <button
                  className={`faq-toggle-button ${activeIndex === `${categoryIndex}-${index}` ? 'open' : ''}`}
                >
                  {activeIndex === `${categoryIndex}-${index}` ? '-' : '+'}
                </button>
              </div>
              <div
                className={`faq-answer ${
                  activeIndex === `${categoryIndex}-${index}` ? 'show' : ''
                }`}
              >
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FaqPage;
