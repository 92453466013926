import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../authContext/authContext';
import { FaUserCircle } from 'react-icons/fa';
import { FaTimes, FaBars } from 'react-icons/fa'; // Import additional icons for the cross and menu

import './Navbar.css';

const Navbar = () => {
  const { authData, setAuthData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference to the dropdown
  const [menuOpen, setMenuOpen] = useState(false);
  let logoClass = '';
  if (authData) {
    logoClass = authData.plan === 'Silver' ? 'silver-shadow' : 'gold-shadow';
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };
  const logout = () => {
    localStorage.removeItem('authToken');
    setAuthData(null);
    navigate('/login');
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="header-left">
          <a href="/" className='navbar-logo'>
            <div className={`logo ${logoClass}`}>
              <img src={process.env.PUBLIC_URL + '/logo_black.jpg'} alt="Logo" className="navbar-logo" />
            </div>
          </a>
        </div>
        <div className="header-right">
          <div
            className='navbar-menu'
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          >
            {menuOpen ? (
              <FaTimes size={24} /> // Display "X" icon when menu is open
            ) : (
              <>
                <span></span> {/* Display three horizontal lines */}
                <span></span>
                <span></span>
              </>
            )}

          </div>
          <ul className={`navLinks ${menuOpen ? "open" : ""}`}>
            {authData && (
              <>
                <li className='navLink'>
                  <Link to="/dashboard" className="navLink" onClick={closeMenu}>Dashboard</Link>
                </li>
                <li className='navLink'>
                  <Link to="/strategy" className="navLink" onClick={closeMenu}>Strategy</Link>
                </li>
              </>
            )}
            <li className='navLink'>
              <Link to="/contact-us" className="navLink contact-us" onClick={closeMenu}>Contact Us</Link>
            </li>

            <li className='navLink'>
              <Link to="/products" className="navLink" onClick={closeMenu}>Products</Link>
            </li>



            {!authData ? (

              <li className='navLink'>
                <Link to="/login" className="navLink" onClick={closeMenu}>Login</Link>
              </li>
            ) : (
              <>
                <li className='navLink'>
                  <Link to="/orders" className="navLink" onClick={closeMenu}>Orders</Link>
                </li>
                <li className='navLink'>
                  <Link to="/referrals" className="navLink" onClick={closeMenu}>Referrals</Link>
                </li>
                <li className='navLink profile'>
                  <Link to="/profile" className="navLink" onClick={closeMenu}>View Profile</Link>
                </li>
                <li className='navLink logout' onClick={() => {
                  logout();
                  closeMenu();
                }}>
                  <div onClick={logout} className="navLink">Logout</div>

                </li>
                <li className='navLink'>

                  <li className="profile-icon" ref={dropdownRef}>
                    <div onClick={toggleDropdown} className="menu-button">
                      <FaUserCircle size={24} />
                    </div>
                    {isDropdownOpen && (
                      <div className="dropdown open">
                        <Link to="/profile" className="dropdown-item-navbar">View Profile</Link>
                        <div onClick={logout} className="dropdown-item-navbar">Logout</div>
                      </div>
                    )}
                  </li>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
