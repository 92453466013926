import React from 'react';
import './LandingPage1.css'; // Import the CSS file

function HeroSection() {
  return (
    <div className="hero-section-unique">
      <div className="hero-section-bg-box-unique">
        <div className="hero-section-bg-img-unique">
          <img src="images/hero-bg.png" alt="hero background" />
        </div>
        <div className="hero-section-content-unique">
          <div className="hero-content-section-left">
            <div className='left-section-box'>

              <h1 className="hero-section-title">WELCOME TO<br/> ETF NINJA</h1>
              <h2 className="hero-section-subtitle">Your Gateway to Smarter, Peaceful ETF Investing
              </h2>
              <p className="hero-section-paragraph">
                At ETF Ki Dukaan, we simplify investing with automated ETF strategies designed to grow
                your wealth effortlessly. Sit back, relax, and watch your money work for you.
              </p>
            </div>
          </div>
          <div className="hero-content-section-right">
            <h1 className="hero-section-title">Right Section Title</h1>
            <p className="hero-section-paragraph">This is the right section content.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const LandingPage = () => {
  return (
    <div className="landing-page-container">
      <HeroSection />
    </div>
  );
};

export default LandingPage;
