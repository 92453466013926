import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import ETFDetailsOverlay from '../ETFDetailsOverlay/ETFDetailsOverlay';
import { FaTrash } from 'react-icons/fa'; // Import the trash icon


const Dashboard = () => {
  const [etfs, setEtfs] = useState([]);
  const [watchlist, setWatchlist] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);

  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null); // Track hovered item
  const [isHoveringWindow, setIsHoveringWindow] = useState(false); // Track if the user is in the action window
  const closeTimeout = useRef(null); // Ref to manage closing timeout
  const [actionWindowPosition, setActionWindowPosition] = useState('below');
  const [watchlistElementActive, setWatchlistElementActive] = useState(false);
  
  const sidebarRef = useRef(null); // Reference to sidebar for height calculations


  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;
  const { authData } = useContext(AuthContext);
  const fetchData = async () => {
    try {
      const response = await axios.get(`${node_backend}/api/etf/etfs`);
      const updatedEtfs = await Promise.all(
        response.data.map(async (etf) => {
          const now = new Date();
          const etfLastUpdate = new Date(etf.updatedAt);
          if ((now - etfLastUpdate) / 1000 > 60) {
            const updatedResponse = await axios.post(`${node_backend}/api/etf/etfs/${etf.symbol}`);
            return updatedResponse.data;
          }
          return etf;
        })
      );
      setEtfs(updatedEtfs);
    } catch (error) {
      console.error('There was an error fetching the ETF data!', error);
    }

    if (authData && authData.email) {
      try {
        const watchlistResponse = await axios.get(`${node_backend}/api/watchlist/watchlist`, {
          params: { email: authData.email },
        });
        setWatchlist(watchlistResponse.data.watchlist || []);
      } catch (error) {
        console.error('There was an error fetching the watchlist data!', error);
      }
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [node_backend, authData]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setDropdownVisible(event.target.value.length > 0);
  };

  const addToWatchlist = (etf) => {
    if (authData && authData.email) {
      axios
        .post(`${node_backend}/api/watchlist/add`, { email: authData.email, symbol: etf.symbol })
        .then((response) => {
          setWatchlist([...watchlist, etf]);
          setDropdownVisible(false);
          setSearchTerm('');
        })
        .catch((error) => {
          console.error('There was an error adding the ETF to the watchlist!', error);
        });
    }
  };

  const removeFromWatchlist = (symbol) => {
    if (authData && authData.email) {
      axios
        .post(`${node_backend}/api/watchlist/delete`, { email: authData.email, symbol: symbol })
        .then((response) => {
          setWatchlist(watchlist.filter((etf) => etf.symbol !== symbol));
        })
        .catch((error) => {
          console.error('There was an error removing the ETF from the watchlist!', error);
        });
    }
  };
  // const handleMouseMove = (event) => {
  //   if (sidebarRef.current) { // Check if sidebarRef is not null
  //     const sidebarHeight = sidebarRef.current.getBoundingClientRect().height;
  //     const mouseY = event.clientY - sidebarRef.current.getBoundingClientRect().top;

  //     if (mouseY / sidebarHeight > 0.8) {
  //       setActionWindowPosition('above');
  //     } else {
  //       setActionWindowPosition('below');
  //     }
  //   }
  // };

  const handleMouseEnter = (symbol, index, event) => {
    clearTimeout(closeTimeout.current);
    setHoveredItem(symbol);
    if (sidebarRef.current) { // Check if sidebarRef is not null
      // Add mousemove listener when hovering over a watchlist item
      // document.addEventListener('mousemove', handleMouseMove);

      // Set action window position based on current hover position
      const sidebarHeight = sidebarRef.current.getBoundingClientRect().height;
      const mouseY = event.clientY - sidebarRef.current.getBoundingClientRect().top;

      if (mouseY / sidebarHeight > 0.8) {
        setActionWindowPosition('above');
      } else {
        setActionWindowPosition('below');
      }
    }
  };
  const handleMouseLeave = () => {
    closeTimeout.current = setTimeout(() => {
      if (!isHoveringWindow) setHoveredItem(null);
    }, 500);
    // Remove mousemove listener when leaving the watchlist item
    // document.removeEventListener('mousemove', handleMouseMove);
  };

  const handleActionWindowEnter = () => {
    clearTimeout(closeTimeout.current);
    setIsHoveringWindow(true);
  };

  const handleActionWindowLeave = () => {
    setIsHoveringWindow(false);
    closeTimeout.current = setTimeout(() => setHoveredItem(null), 500);
  };


  // const handleItemClick = (symbol) => {
  //   setActiveItem((prev) => (prev === symbol ? null : symbol));
  // };
  const closeOverlay = () => {
    setOverlayVisible(false);
    setSelectedSymbol(null);
  };

  const filteredEtfs = etfs.filter((etf) =>
    etf.symbol.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="dashboard">
      <div ref={sidebarRef} className="sidebar">
        <h3>Watchlist</h3>
        <div className='search-bar-container'>

          <input
            type="text"
            placeholder="Search by symbol..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-bar"
          />
          {searchTerm && (
            <button
              className="clear-search-button"
              onClick={() => {
                setSearchTerm('');
                setDropdownVisible(false);
              }}
            >
              ×
            </button>
          )}
        </div>
        {dropdownVisible && (
          <ul className="dashboard-dropdown">
            {filteredEtfs.map((etf, index) => (
              <li key={index} className="dashboard-dropdown-item">
                <div className='dropdown-symbol'>
                  {etf.symbol}
                </div>
                {/* <div className='dropdown-hypthen'>
                  -
                </div>
                <div className='dropdown-price'>

                  ₹{etf.price}
                </div> */}
                <button onClick={() => addToWatchlist(etf)} className="add-button">
                  Add
                </button>
              </li>
            ))}
          </ul>
        )}
        {!dropdownVisible && (<ul className="watchlist">
          {watchlist.map((etf, index) => (
            <li
              key={index}
              className={`watchlist-item ${etf.change <= 0 ? 'stock-down' : 'stock-up'}`}
              onMouseEnter={(event) => handleMouseEnter(etf.symbol, index, event)} // Explicitly pass the event
              onMouseLeave={handleMouseLeave}
            >
              <div className="stock-info">
                <div className='stock-info-left'>
                  <span className="stock-symbol">{etf.symbol}</span>
                </div>
                <div className='stock-info-right'>
                  <div className='stock-info-right-top'>
                    <span className={`stock-ltp ${etf.change >= 0 ? 'positive' : 'negative'}`}>
                      ₹{etf.price}
                    </span>
                  </div>

                  <span className="stock-change">
                    {etf.change > 0 ? '+' : ''}
                    {etf.change.toFixed(2)} ({etf.changePercent.toFixed(2)}%)
                  </span>
                </div>
              </div>
              {hoveredItem === etf.symbol && (
                <div
                  className={`action-window ${actionWindowPosition} ${isHoveringWindow ? 'show' : ''}`}
                  onMouseEnter={handleActionWindowEnter}
                  onMouseLeave={handleActionWindowLeave}
                >
                  <button
                    className="button"
                    onClick={() => {
                      setSelectedSymbol(etf.symbol); // Set the selected ETF symbol
                      setOverlayVisible(true); // Show the overlay
                    }}
                  >
                    Buy/Sell
                  </button>
                  <button
                    className="remove-button"
                    onClick={() => removeFromWatchlist(etf.symbol)}
                  >
                    <FaTrash />
                  </button>
                </div>
              )}
              <div className="stock-buttons">

              </div>
            </li>
          ))}
        </ul>)}
      </div>

      {overlayVisible && (
        <ETFDetailsOverlay symbol={selectedSymbol} onClose={closeOverlay} />
      )}
    </div>
  );
};

export default Dashboard;