import React, { useState, useContext, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "font-awesome/css/font-awesome.min.css";
import "./style.css"; // Add your custom styles
import "./responsive.css"; // Add responsive styles
import "./LandingPage.css"
import BacktestedResults from '../BacktestedResults/BacktestedResults';



function HeroSection() {
  return (
    <div className="hero_area">
      <div className="hero_bg_box">
        <div className="bg_img_box">
          <img src="images/hero-bg.png" alt="hero background" />
        </div>
      </div>
      <SliderSection /> {/* Move the SliderSection inside the HeroSection */}
    </div>
  );
}

function SliderSection() {
  const [showBacktestedResults, setShowBacktestedResults] = useState(false); // State for backtested results

  const handleBacktestedResultsClick = () => {
    setShowBacktestedResults(true); // Show the overlay when the button is clicked
  };

  const closeBacktestedResults = () => {
    setShowBacktestedResults(false); // Close the overlay
  };
  return (
    <div className="slider_section">
      <div id="customCarousel1" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="detail-box">
                    <h1>
                      <div className='detail-box-heading'>

                        Welcome to  <br />
                        ETF Ki Dukaan
                      </div>
                    </h1>
                    <p>
                      <div className='detail-box-subheadng'>

                        Your Gateway to Smart Investing with ETFs
                      </div>
                      <div className='detail-box-text'>

                        At ETF Ki Dukaan, we simplify investing with automated ETF strategies designed to grow
                        your wealth effortlessly. Sit back, relax, and watch your money work for you.
                      </div>

                    </p>
                    <div className="btn-box">
                      <a href="/login" className="btn1">
                        Get Started
                      </a>
                    </div>
                    <div className="btn-box">

                      <button onClick={handleBacktestedResultsClick} className="btn1">
                        Show Backtested Results
                      </button>
                    </div>
                    {showBacktestedResults && (
                      <div className="backtested-results-overlay">
                        <BacktestedResults onClose={closeBacktestedResults} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-box">
                    <img src="images/slider-img.png" alt="slider" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Repeat similar blocks for other slides */}
        </div>
      </div>
    </div>
  );
}
function ServiceSection() {
  return (
    <section className="service_section layout_padding">
      <div className="service_container">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>
              Our <span>Services</span>
            </h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="box">
                <div className="img-box">
                  <img src="images/msg.png" alt="Buy/Sell Notifications" />
                </div>
                <div className="detail-box">
                  <h5>Buy/Sell Notifications</h5>
                  <p>
                    Receive timely buy and sell alerts via WhatsApp and email, ensuring you never miss an opportunity.
                  </p>
                  {/* <a href="">Read More</a> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box">
                <div className="img-box">
                  <img src="images/tracking.png" alt="Holdings Tracking" />
                </div>
                <div className="detail-box">
                  <h5>Holdings Tracking</h5>
                  <p>
                    Keep track of your ETF holdings effortlessly with our comprehensive tracking service.
                  </p>
                  {/* <a href="">Read More</a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="box">
                <div className="img-box">
                  <img src="images/s3.png" alt="Customized Strategies" />
                </div>
                <div className="detail-box">
                  <h5>Customized Strategies</h5>
                  <p>
                    Choose from a selection of strategies tailored to your risk-reward preferences, backed by robust backtested reports.
                  </p>
                  {/* <a href="">Read More</a> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box">
                <div className="img-box">
                  <img src="images/automation.png" alt="Future Automation" />
                </div>
                <div className="detail-box">
                  <h5>Automation</h5>
                  <p>
                    Automation features that simplify the implementation of your chosen strategies.
                  </p>
                  {/* <a href="">Read More</a> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="btn-box">
            <a href="">View All</a>
          </div> */}
        </div>
      </div>
    </section>
  );
}

function WhyChooseUs() {
  return (
    <section className="about_section layout_padding">
      <div className="container">
        <div className="heading_container heading_center">
          <h2>Why Choose Us?</h2>
        </div>


        <div className="row">
          {/* <div className="col-md-6">
            <div className="img-box">
              <img src="images/why-choose-us.png" alt="about us" />
            </div>
          </div> */}
          <div className="col-md-12">


            <div className="row">
              <div className="col-md-4">
                <div className="box">
                  <div className="img-box">
                    <div className="wcu-img">

                      <img src="images/strategies.png" alt="Buy/Sell Notifications" />
                    </div>
                  </div>
                  <div className="detail-box">
                    <div className="wcu-heading">

                      <h5>Proven Strategies</h5>
                    </div>
                    <p>
                      Our investment strategies are fully backtested and designed to deliver low-risk and high-reward outcomes.                  </p>
                    {/* <a href="">Read More</a> */}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="box">
                  <div className="img-box">
                    <div className="wcu-img">

                      <img src="images/peace.png" alt="Holdings Tracking" />
                    </div>
                  </div>
                  <div className="detail-box">
                    <div className="wcu-heading">

                      <h5> Peace of Mind</h5>
                    </div>
                    <p>
                      Enjoy the tranquility of knowing your investments are automatically managed with utmost precision and care.
                    </p>
                    {/* <a href="">Read More</a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="box">
                  <div className="img-box">
                    <div className="wcu-img">

                      <img src="images/tracking.png" alt="Holdings Tracking" />
                    </div>
                  </div>
                  <div className="detail-box">
                    <div className="wcu-heading">

                      <h5>Expert Insights

                      </h5>
                    </div>
                    <p>
                      Benefit from our in-depth analysis and expert recommendations to make informed investment decisions.                    </p>
                    {/* <a href="">Read More</a> */}
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </section>
  );
}
function ExpenseRatio() {
  return (
    <section className="about_section_expense layout_padding_expense">
      <div className="container">

        <div className="heading_container_expense heading_center">
          <h2>Expense Ratio Comparison</h2>
        </div>

        <div className="row">
          <div className="col-md-12">
            <section className="understanding-expense-ratios">
              <div className="section-title">
                <h3 className="expense-heading">Understanding Expense Ratios</h3>
              </div>
              <div className="section-content">
                <p className="expense-paragraph">
                  The expense ratio represents the annual fee that cd s charge their investors to cover operational costs. It's crucial for investors to understand these costs as they directly impact the net returns on their investments.
                </p>
              </div>
            </section>

            <section className="fund-types">
              <div className="section-title">
                <h3 className="expense-heading">Fund Types</h3>
              </div>

              <div className="fund-type-card">
                <div className="fund-type-title">
                  <h4 className="expense-subheading">Equity Mutual Funds</h4>
                </div>
                <div className="fund-type-content">
                  <p className="expense-paragraph">Equity mutual funds invest primarily in stocks. They are actively managed, meaning fund managers make decisions about which stocks to buy and sell.</p>
                  <p className="expense-paragraph">Expense Ratio: Typically ranges from 1.5% to 2.5%.</p>
                  <p className="expense-paragraph">Why Higher?: Actively managed funds require extensive research and frequent trading, leading to higher management fees.</p>
                </div>
              </div>

              <div className="fund-type-card">
                <div className="fund-type-title">
                  <h4 className="expense-subheading">Debt Mutual Funds</h4>
                </div>
                <div className="fund-type-content">
                  <p className="expense-paragraph">Debt mutual funds invest in bonds and other fixed-income securities. They can be actively or passively managed.</p>
                  <p className="expense-paragraph">Expense Ratio: Typically ranges from 0.5% to 1.5%.</p>
                  <p className="expense-paragraph">Why Lower?: Debt funds usually involve less frequent trading and lower management costs compared to equity funds.</p>
                </div>
              </div>

              <div className="fund-type-card">
                <div className="fund-type-title">
                  <h4 className="expense-subheading">Index Mutual Funds</h4>
                </div>
                <div className="fund-type-content">
                  <p className="expense-paragraph">Index mutual funds aim to replicate the performance of a specific index, such as the S&P 500. They are passively managed.</p>
                  <p className="expense-paragraph">Expense Ratio: Typically ranges from 0.1% to 0.5%.</p>
                  <p className="expense-paragraph">Why Lower?: Passive management involves less research and trading, leading to lower operational costs.</p>
                </div>
              </div>

              <div className="fund-type-card">
                <div className="fund-type-title">
                  <h4 className="expense-subheading">ETFs (Exchange-Traded Funds)</h4>
                </div>
                <div className="fund-type-content">
                  <p className="expense-paragraph">ETFs can track indices, sectors, commodities, or other assets and are traded like stocks on an exchange.</p>
                  <p className="expense-paragraph">Expense Ratio: Typically ranges from 0.03% to 0.75%.</p>
                  <p className="expense-paragraph">Why Lowest?: ETFs often have lower costs due to their passive management style and the efficiencies of trading on an exchange.</p>
                </div>
              </div>
            </section>

            <section className="comparative-snapshot">
              <div className="section-title">
                <h3 className="expense-heading">Comparative Snapshot</h3>
              </div>
              <div className="table-container">
                <table className="expense-table">
                  <thead>
                    <tr>
                      <th>Fund Type</th>
                      <th>Typical Expense Ratio</th>
                      <th>Management Style</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Equity Mutual Funds</td>
                      <td>1.5% - 2.5%</td>
                      <td>Active</td>
                    </tr>
                    <tr>
                      <td>Debt Mutual Funds</td>
                      <td>0.5% - 1.5%</td>
                      <td>Active/Passive</td>
                    </tr>
                    <tr>
                      <td>Index Mutual Funds</td>
                      <td>0.1% - 0.5%</td>
                      <td>Passive</td>
                    </tr>
                    <tr>
                      <td>ETFs</td>
                      <td>0.03% - 0.75%</td>
                      <td>Passive</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>



      </div>
      <div className='about_section conclusion'>

        <div className="heading_container heading_center">
          {/* <section></section> */}

          <h2>Conclusion</h2>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="conclusion-text">
              <p className="expense-paragraph">
                Expense ratios are a key consideration when choosing between different investment vehicles. While actively managed equity mutual funds offer the potential for higher returns, they come with higher costs. On the other hand, index mutual funds and ETFs provide a cost-effective way to gain market exposure with lower expense ratios.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


function OurCommitment() {
  return (
    <section className="service_section layout_padding">
      <div className="service_container">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>Our Commitment</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="img-box-our-commitment">
                <img className='img-our-commitment' src="images/commitment.png" alt="about us" />
              </div>
            </div>

            <div className="col-md-6">
              <div className="detail-box-our-commitment">
                <p>
                  We are committed to providing an accessible and user-friendly platform that caters to both novice investors and seasoned market participants. Our formal yet friendly approach ensures that you receive the support and guidance you need to navigate the world of ETFs confidently.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}




function JoinUs() {
  return (
    <section className="service_section layout_padding">
      <div className="service_container">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>Join Us</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="img-joinus-commitment-div">
                <img className='img-joinus-commitment' src="images/computer.png" alt="about us" />
              </div>

            </div>

            <div className="col-md-6">
              <div className='detail-box-joinus-wrapper'>

                <div className="detail-box-joinus">
                  <p>
                    Embark on your investment journey with ETF Ki Dukaan and experience the benefits of strategic ETF investing. Together, we can achieve your financial goals with peace of mind and precision.
                  </p>

                  <div className="btn-box">
                    <a href="/register" className="btn1">
                      Sign Up
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="footer">
        <div className="footer-content">
          <p className="expense-footer-text">
            <small>
              Disclaimer: Investing in ETFs involves risks, including the possible loss of principal. Past performance does not guarantee future results. ETF Ki Dukaan does not provide personalized investment advice and is not registered with the Securities and Exchange Board of India (SEBI). Please consult a financial advisor before making any investment decisions.
            </small>
          </p>
        </div>
      </div>
    </section>
  );
}

function Disclaimer() {

}
// Main App Component
function LandingPage() {

  return (
    <div>
      <HeroSection />
      {/* <ServiceSection /> */}
      <WhyChooseUs />
      {/* <OurCommitment /> */}
      {/* <ExpenseRatio /> */}
      <JoinUs />

      {/* Include other sections similarly */}
    </div>
  );
}

export default LandingPage;
