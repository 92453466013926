import React, { useState } from 'react';
import Dashboard from '../../pages/Dashboard/Dashboard';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'; // Import arrow icons

import './Layout.css';

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="layout">
      <button
        className={`sidebar-toggle-button ${isSidebarOpen ? 'hidden' : ''}`}
        onClick={toggleSidebar}
        aria-label="Open Sidebar"
      >
        <FaChevronRight size={24} />
      </button>

      <div
        className={`dashboard-container ${isSidebarOpen ? 'open' : ''}`}
      >
        {isSidebarOpen && (
          <button
            className="close-sidebar-btn"
            onClick={toggleSidebar}
            aria-label="Close Sidebar"
          >
            <FaChevronLeft size={24} />
          </button>
        )}
        <Dashboard />
      </div>

      <div className="main-content">{children}</div>
    </div>
  );
};

export default Layout;
