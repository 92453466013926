import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
// import LandingPage from './pages/LandingPage/LandingPage';
import LandingPage from './pages/LandingPage/LandingPage';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import { AuthProvider } from './authContext/authContext';
import Layout from './components/Layout/Layout';
import Products from './pages/ProductPage/ProductPage';
import HomePage from './pages/HomePage/HomePage';
import Strategy from './pages/Strategy/StrategyPage';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ETFDetails from './pages/ETFDetails/ETFDetails'; // Added this import
import ProfilePage from './pages/ProfilePage/ProfilePage'; // Add this import
import AdminPage from './pages/AdminPage/AdminPage';
import ContactUs from './pages/ContactUs/ContactUs';
import Referrals from './pages/ReferralPage/ReferralsPage';
import OrdersPage from './pages/OrdersPage/OrdersPage';
import PortfolioPage from './pages/PortfolioPage/PortfolioPage';
import LandingPage1 from './pages/LandingPage1/LandingPage1';
import FaqPage from './pages/FaqPage/FaqPage';
// import AdminPaymentsPage from './pages/AdminPaymentsPage/AdminPaymentsPage';
// import AdminPaymentsPaimport age from './pages/AdminPaymentsPage/AdminPaymentsPage'; // Import the new page
import AdminPaymentsPage  from './pages/AdminPaymentsPage/AdminPaymentsPage'
import { Navigate } from 'react-router-dom'; // Import Navigate for redirect

// Secure route wrapper for authentication check
const AdminRoute = ({ element }) => {
  const token = localStorage.getItem('authToken');
  return token ? element : <Navigate to="/admin" />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/admin" element={<AdminPage />} />
            {/* <Route path="/admin/payments" element={<AdminRoute element={<AdminPaymentsPage />} />} /> Secure Route */}

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/products" element={<Products />} />
            <Route path="/forgot-password" element={<ForgotPassword />} /> 
            <Route path="/" element={<LandingPage/>} />
            <Route path="/1" element={<LandingPage1/>} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/faq" element={<FaqPage />} />

            <Route
              path="*"
              element={
                <Layout>
                  <Routes>
                    
                    <Route path="/orders" element={<OrdersPage />} />
                    <Route path="/dashboard" element={<HomePage />} />
                    <Route path="/strategy" element={<Strategy />} /> 
                    <Route path="/etf/:symbol" element={<ETFDetails />} /> {/* Added this route */}
                    <Route path="/referrals" element={<Referrals />} /> 
                    <Route path="/profile" element={<ProfilePage />} /> 
                    <Route path="/portfolio" element={<PortfolioPage />} /> 


                    {/* Add other routes here */}
                  </Routes>
                </Layout>
              }
            />
            {/* Add other routes here */}
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
